import React, { useEffect, useState } from "react";
import {
  Stack,
  IStackTokens,
  IStackStyles,
  PrimaryButton,
  IStackProps,
  TextField,
  Dropdown,
  IDropdownStyles,
} from "@fluentui/react";
import {
  companyData,
  logoAthena,
  logoEG,
  logoGreco,
  logoSagauta,
  logoVGM,
} from "./CompanyData";
import { LT } from "../logos/lt";
import { LT_ENG } from "../logos/lt-eng";
import { sagaudaSublogo } from "../logos/sagauda-sublogo";

const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    width: "960px",
    margin: "20px auto",
    color: "",
  },
};
const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: 300 } },
};
const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

export const Signature: React.FunctionComponent = () => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [companyId, setCompanyId] = useState(0);

  const companiesList: any = companyData.map((company) => ({
    key: company.id,
    text: company.companyDisplayName,
  }));
  const [state, setState] = useState<any>(companyData[0]);

  useEffect(() => {
    const newState = companyData.filter(
      (company) => company.id === companyId
    )[0];
    setState({
      ...newState,
      firstLastName: state.firstLastName,
      jobTitle: state.jobTitle,
      email: state.email,
      // teamsText: state.teamsText,
    });
  }, [companyId, state.email, state.firstLastName, state.jobTitle]);

  const copyToClipboard = () => {
    var doc: any = document;
    var text: any = doc.getElementById("content");
    var range: any;
    var selection: any;

    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = doc.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    document.execCommand("copy");
    window?.getSelection()?.removeAllRanges();
    setCopySuccess(true);

    setTimeout(() => {
      setCopySuccess(false);
    }, 5000);
  };

  return (
    <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
      <div id="content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "9pt",
            fontFamily:
              "Calibri, 'Segoe UI', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Arial', sans-serif",
            fontWeight: 400,
          }}
        >
          <div>
            <div>
              <b>{state.firstLastName}&nbsp;</b>
              {state.firstLastName && state.jobTitle ? `| ` : ``}
              {state.jobTitle}
            </div>
          </div>
          <div>
            {state.telephone ? `T ` : ``}
            {state.telephone}
            {state.telephone && state.mobile ? ` |` : ``}
            {state.mobile ? `  M ` : ``}
            {state.mobile}
            {(state.fax && state.mobile) || (state.telephone && state.fax)
              ? ` |`
              : ``}
            {state.fax ? ` F ` : ``}
            {state.fax}
          </div>
          <div>
            {/* <p
              style={{
                marginTop: 0,
                marginBottom:
                  state.teamsText.length > 0 && state.email?.length > 0
                    ? 0
                    : "9pt",
              }}
            > */}
            <p
              style={{
                marginTop: 0,
                marginBottom: "9pt",
              }}
            >
              <a href={`mailto:${state.email}`}>{state.email}</a>
            </p>
            {/* {state.teamsText.length > 0 && state.email?.length > 0 && <p style={{ marginTop: 0, marginBottom: '9pt' }}>
              <a href={`https://teams.microsoft.com/l/chat/0/0?users=${state.email}`} target="_blank">{state.teamsText}</a>
            </p>} */}
          </div>

          <table style={{ fontSize: "9pt", borderCollapse: "collapse" }}>
            <tr>
              {(logoAthena.includes(state.id) ||
                logoVGM.includes(state.id)) && (
                <>
                  {logoVGM.includes(state.id) && (
                    <td>
                      <p>
                        <img
                          width="94"
                          height="32"
                          src={`data:image/png;base64, ${state.logo}`}
                          alt="logo"
                        />
                      </p>
                    </td>
                  )}

                  {logoAthena.includes(state.id) && (
                    <td>
                      <p>
                        <img
                          width="150"
                          height="32"
                          src={`data:image/png;base64, ${state.logo}`}
                          alt="logo"
                        />
                      </p>
                    </td>
                  )}
                </>
              )}
            </tr>
            <tr>
              {(logoGreco.includes(state.id) || logoEG.includes(state.id)) && (
                <td width="75">
                  <img
                    width="60"
                    height="60"
                    src={`data:image/png;base64, ${state.logo}`}
                    alt="Logo"
                  />
                </td>
              )}
              {logoSagauta.includes(state.id) && (
                <td width="75">
                  <img
                    width="60"
                    height="72"
                    src={`data:image/png;base64, ${state.logo}`}
                    alt="logo"
                  />
                </td>
              )}
              <td>
                <div>
                  <b>{state.companyName}</b>
                </div>
                <div>{state.activityDescription}</div>
                <div>
                  {state.streetNumber}
                  {state.countryZIP && state.streetNumber ? ` | ` : ``}
                  {state.countryZIP}
                  {state.countryZIP || state.streetNumber ? ` | ` : ``}
                  {state.website.split(",").map((w: any, i: any) => (
                    <a
                      key={i}
                      style={{ marginRight: "5px" }}
                      href={`https://${w.trim()}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {w.trim()}
                    </a>
                  ))}
                </div>
                <div>
                  {state.infoOne}
                  {state.infoOne && state.infoTwo ? ` | ` : ``}
                  {state.infoTwo}
                  {(state.infoTwo || state.infoOne) && state.infoThree
                    ? ` | `
                    : ``}
                  {state.infoThree}
                  {(state.infoTwo || state.infoOne || state.infoThree) &&
                  state.infoFour
                    ? ` | `
                    : ``}
                  {state.infoFour}
                  {(state.infoTwo ||
                    state.infoOne ||
                    state.infoThree ||
                    state.infoFour) &&
                  state.infoFive
                    ? ` | `
                    : ``}
                  {state.infoFive}
                </div>
              </td>
            </tr>
          </table>

          {state?.additionalLink?.length > 0 && (
            <div style={{ marginTop: "9pt", marginBottom: "0" }}>
              {state?.additionalLink?.map((item: any) => (
                <>
                  <p style={{ lineHeight: "11pt", margin: 0 }}>{item.text}</p>
                  <p style={{ lineHeight: "11pt", margin: 0 }}>
                    <a href={item.linkURL} target="_blank" rel="noreferrer">
                      {item.linkText}
                    </a>
                  </p>
                </>
              ))}
            </div>
          )}

          <p style={{ marginTop: "9pt", marginBottom: "9pt" }}>
            {state.linkedIn && (
              <>
                <a href={state.linkedIn} target="_blank" rel="noreferrer">
                  LinkedIn
                </a>
                &nbsp;|&nbsp;
              </>
            )}
            {state.XING && (
              <>
                <a href={state.XING} target="_blank" rel="noreferrer">
                  XING
                </a>
                &nbsp;|&nbsp;
              </>
            )}
            {state.twitter && (
              <>
                <a href={state.twitter} target="_blank" rel="noreferrer">
                  Twitter
                </a>
                &nbsp;|&nbsp;
              </>
            )}
            {state.facebook && (
              <>
                <a href={state.facebook} target="_blank" rel="noreferrer">
                  Facebook
                </a>
                &nbsp;|&nbsp;
              </>
            )}
            {state.instagram && (
              <a href={state.instagram} target="_blank" rel="noreferrer">
                Instagram
              </a>
            )}
          </p>
          {state.id === 23 && (
            <div>
              <img height="55" src={`${LT_ENG}`} alt="logo" />
            </div>
          )}
          {state.id === 24 && (
            <div>
              <img height="55" src={`${LT}`} alt="logo" />
            </div>
          )}
          {(state.id === 11 || state.id === 25) && (
            <div>
              <img height="72" src={`${sagaudaSublogo}`} alt="logo" />
            </div>
          )}

          {state?.protections?.length > 0 && (
            <div>
              {state?.protections?.map((protect: any) => (
                <p style={{ lineHeight: "11pt", margin: 0 }}>
                  <a
                    href={protect.protectionURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {protect.protectionText}
                  </a>
                </p>
              ))}
            </div>
          )}
          {state.legalLocal && (
            <p
              style={{ marginBottom: 0, marginTop: "9pt" }}
              dangerouslySetInnerHTML={{ __html: state.legalLocal }}
            ></p>
          )}
          {state.legalEnglisch && (
            <p
              style={{ marginTop: "9pt", marginBottom: "9pt" }}
              dangerouslySetInnerHTML={{ __html: state.legalEnglisch }}
            ></p>
          )}
        </div>
      </div>

      <PrimaryButton
        text={copySuccess ? "Copied" : "Copy"}
        onClick={copyToClipboard}
        allowDisabledFocus
      />

      <Stack horizontal tokens={stackTokens} styles={stackStyles}>
        <Stack {...columnProps}>
          <Dropdown
            placeholder="Select Company"
            label="Select Company"
            selectedKey={state.id}
            options={companiesList}
            onChange={(_event: any, option?: any, _index?: number) => {
              setCompanyId(option?.key || 0);
            }}
            styles={dropdownStyles}
          />

          <TextField
            label="Email"
            value={state.email}
            onChange={(_event, newValue) =>
              setState({ ...state, email: newValue || "" })
            }
          />

          <TextField
            label="Fax"
            value={state.fax}
            onChange={(_event, newValue) =>
              setState({ ...state, fax: newValue || "" })
            }
          />
          <TextField
            label="Activity Description"
            value={state.activityDescription}
            onChange={(_event, newValue) =>
              setState({ ...state, activityDescription: newValue || "" })
            }
          />
          <TextField
            label="Info Three"
            value={state.infoThree}
            onChange={(_event, newValue) =>
              setState({ ...state, infoThree: newValue || "" })
            }
          />
          <TextField
            label="Legal Local"
            value={state.legalLocal}
            onChange={(_event, newValue) =>
              setState({ ...state, legalLocal: newValue || "" })
            }
          />
        </Stack>
        <Stack {...columnProps}>
          <TextField
            label="First and Last Name"
            value={state.firstLastName}
            onChange={(_event, newValue) =>
              setState({ ...state, firstLastName: newValue || "" })
            }
          />
          <TextField
            label="Telephone"
            value={state.telephone}
            onChange={(_event, newValue) =>
              setState({ ...state, telephone: newValue || "" })
            }
          />
          <TextField
            label="Street, Number"
            value={state.streetNumber}
            onChange={(_event, newValue) =>
              setState({ ...state, streetNumber: newValue || "" })
            }
          />
          <TextField
            label="Info One"
            value={state.infoOne}
            onChange={(_event, newValue) =>
              setState({ ...state, infoOne: newValue || "" })
            }
          />
          <TextField
            label="Info Four"
            value={state.infoFour}
            onChange={(_event, newValue) =>
              setState({ ...state, infoFour: newValue || "" })
            }
          />
          {/* <TextField
            label="Teams Text"
            value={state.teamsText}
            onChange={(_event, newValue) => setState({ ...state, teamsText: newValue || '' })}
          /> */}
        </Stack>
        <Stack {...columnProps}>
          <TextField
            label="Job Title"
            value={state.jobTitle}
            onChange={(_event, newValue) =>
              setState({ ...state, jobTitle: newValue || "" })
            }
          />
          <TextField
            label="Mobile"
            value={state.mobile}
            onChange={(_event, newValue) =>
              setState({ ...state, mobile: newValue || "" })
            }
          />
          <TextField
            label="Country, ZIP"
            value={state.countryZIP}
            onChange={(_event, newValue) =>
              setState({ ...state, countryZIP: newValue || "" })
            }
          />
          <TextField
            label="Info Two"
            value={state.infoTwo}
            onChange={(_event, newValue) =>
              setState({ ...state, infoTwo: newValue || "" })
            }
          />
          <TextField
            label="Info Five"
            value={state.infoFive}
            onChange={(_event, newValue) =>
              setState({ ...state, infoFive: newValue || "" })
            }
          />
        </Stack>
      </Stack>
      <div style={{ paddingBottom: "60px" }}></div>
    </Stack>
  );
};
