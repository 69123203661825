import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { mergeStyles, initializeIcons } from '@fluentui/react';
import reportWebVitals from './reportWebVitals';
initializeIcons();

mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
    fontSize: '11pt',
    fontFamily: "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, Roboto, 'Helvetica Neue', sans-serif",
  },
});

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
