import React from "react";
import {
  IStackStyles,
  IStackTokens,
  Label,
  Pivot,
  PivotItem,
  Stack,
} from "@fluentui/react";
import Card from "./Card";
import { Signature } from "./Signature/Signature";

const stackStyles: Partial<IStackStyles> = {
  root: {
    width: "960px",
    margin: "20px auto",
    color: "",
  },
};
const stackTokens: IStackTokens = { childrenGap: 15 };

export const App: React.FunctionComponent = () => {
  const labelStyles: any = {
    root: { marginTop: 10 },
  };

  return (
    <Stack styles={stackStyles} tokens={stackTokens}>
      <Pivot aria-label="Basic Pivot Example">
        <PivotItem headerText="Signature">
          <Label styles={labelStyles}>
            <Signature />
          </Label>
        </PivotItem>
        {/* <PivotItem headerText="Card">
          <Label styles={labelStyles}>
            <Card />
          </Label>
        </PivotItem> */}
      </Pivot>
    </Stack>
  );
};
